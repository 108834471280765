import React, { useState } from "react";
import "./App.css";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.bdschoolghor",
      "_blank"
    );
  };

  return (
    <div className="app">
      <h1 className="header">welcome to school ghor</h1>
      <div className="button-container">
        <button className="action-button" onClick={openGooglePlay}>
          Open Play Store
        </button>
        <button className="action-button" onClick={openModal}>
          About
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>
              The School Ghor app is related to educational institutes
              information. Where citizens can know information about the
              educational institutes at home, such as admission information,
              monthly fee, session fee, location of the institution, etc.
              Several newspapers in Bangladesh have reported School Ghor app.
              Professor M. Jahirul Islam, PhD., PEng., a former director of the
              Institute of Information and Communication Technology (IICT) and
              a current professor in the Department of Computer Science &
              Engineering at Shahjalal University of Science and Technology,
              has praised the School Ghor app.
            </p>
            <p><strong>Email:</strong> contact@schoolghor.xyz</p>
            <p>
              <strong>Note:</strong> Currently, no activities are being
              conducted on our website. Download our app to view and use our
              activities.
            </p>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
