import React from 'react';
import './Privecy.css';

const PrivacyPolicy = () => {
  return (
    <div className="body">
      <div className="container">
        <h1>privacy policy for school ghor</h1>
        <p>
        The School Ghor App does not collect any personal information in any form. Permission is obtained from the respective institute before publishing any institutional information (non-public data). Once the institute grants permission, the information is published.All information published or submitted through the School Ghor App is kept completely secure and is neither shared nor sold to any third party. The information of the applicant is kept confidential, it is not published publicly.
        </p>
        </div> 
    </div>
  );
};

export default PrivacyPolicy;
