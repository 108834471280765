import React from 'react';
import { useHistory } from 'react-router-dom';
import '../Login/Login.css';

const ThankYou = () => {
    const history = useHistory();
    const loginbtn = () => {
        history.push("/login")
    }
    return (
        <div className="bodyy">
            <div className="containerr">
                <div className="titlee">আপনার আবেদনের জন্য ধন্যবাদ । আবেদন পর্যালোচনা করে শিঘ্রই প্রকাশ করা হবে</div> <br />
            </div>
        </div>
    );
};

export default ThankYou;