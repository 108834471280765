import firebase from 'firebase/firebase'
import 'firebase/auth'

var firebaseConfig = {
  apiKey: "AIzaSyA6wWrELNSU9RvzFnfr0wIsqoumtYIlhCw",
  authDomain: "bdschoolghor.firebaseapp.com",
  projectId: "bdschoolghor",
  storageBucket: "bdschoolghor.appspot.com",
  messagingSenderId: "542148244136",
  appId: "1:542148244136:web:9f45c1670107ffc900c89d"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
