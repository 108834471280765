import React from 'react'

const SucccessScreen = () => {

  return (
    <div>
      <h1>Subscription Successfull. Now you can listen</h1>
    </div>
  )
}

export default SucccessScreen
