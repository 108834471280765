import React, { useContext } from 'react';
import '../Redy/Redy.css';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { UserContext } from '../../App';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faDotCircle, faLock, faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons'

const Redy = () => {
  let history = useHistory();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const onSubmit = data => {
    fetch('http://localhost:9000/dsregi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'সফলভাবে পেমেন্ট হয়েছে',
            showConfirmButton: false,
            timer: 2000
          })
          history.push('/clicknow')
        }
      })
  }

  console.log(watch("example")); // watch input value by passing the name of it
  return (
    <div className='body'>

      <div className="container">

        <div className='title'>রেজিস্ট্রেশন</div>

        <form onSubmit={handleSubmit(onSubmit)}>

          <div class="user-details">
            <div className="input-box">

              <input {...register('ina', { required: true })} placeholder=' প্রতিষ্ঠানের পূর্ণ নাম' />
            </div>

            <div className="input-box">

              <input {...register('iad', { required: true })} placeholder=' প্রতিষ্ঠানের পূর্ণ ঠিকানা' />
            </div>
            <div className="input-box">

              <input {...register('iq', { required: true })} placeholder=' প্রতিষ্ঠানের ধরন (স্কুল , কলেজ)' />
            </div>
            <div className="input-box">

            <input {...register('iv', { required: true })} placeholder=' কারিকুলাম(বাংলা , ইংরেজি)' />
            </div>
            <div className="input-box">

            <input {...register('ib', { required: true })} placeholder=' শাখা সমূহ(বিজ্ঞান , মানবিক , ব্যবসা )' />
            </div>
            <div className="input-box">

            <input {...register('iweb')} placeholder=' প্রতিষ্ঠানের ওয়েব-সাইট( যদি থাকে ) ' />
            </div>
            <div className="input-box">

            <input {...register('imail')} placeholder=' প্রতিষ্ঠানের ইমেইল( যদি থাকে ) ' />
            </div>
            <div className="input-box">

            <input {...register('iha', { required: true })} placeholder=' প্রতিষ্ঠান প্রধানের নাম ' />
            </div>
            <div className="input-box">

            <input {...register('iham', { required: true })} placeholder=' প্রতিষ্ঠান প্রধানের মোবাইল নাম্বার ' />
            </div>
            <div className="input-box">

            <input {...register('im', { required: true })} placeholder=' প্রতিষ্ঠানের অফিসিয়াল মোবাইল নাম্বার ' />
            </div>
            <div className="input-box">

            <input {...register('ira', { required: true })} placeholder=' আবাসনঃ আবাসিক/অনাবাসিক ' />
            </div>
            <div className="input-box">

            <input {...register('icla', { required: true })} placeholder=' শ্রেণী সমূহের নাম ' />
            </div>
            <div className="input-box">

            <input {...register('icha', { required: true })} placeholder=' বৈশিষ্ট্য ( বিস্তারিত ) ' />
            </div>
            <div className="input-box">

            <input {...register('iaf', { required: true })} placeholder=' শ্রেণীসমূহের ভর্তি ফি' />
            </div>
            <div className="input-box">

            <input {...register('imf', { required: true })} placeholder=' শ্রেণীসমূহের মাসিক ফি ' />
            </div>
            <div className="input-box">

            <input {...register('isaf', { required: true })} placeholder=' শ্রেণীসমূহের সেশন ফি ' />
            </div>
            <div className="input-box">

            <input {...register('ihf')} placeholder=' হোস্টেল ফি ( যদি থাকে ) ' />
            </div>
            <div className="input-box">

            <input {...register('icsn', { required: true })} placeholder=' শ্রেণীসমূহের আসন সংখ্যা ' />
            </div>
          </div>

          <button type='submit' className="btn" > <FontAwesomeIcon icon={faDotCircle} /> সাবমিট করুন</button>
          {/* <button  className="btn">ক্লিক করুন</button> */}


        </form>
      </div>

    </div>
  );
};

export default Redy;
